export default defineAppConfig({
  saasName: 'Plenamar',
  saasLink: 'https://plenamar.fsbd.ai',
  customSaas: true,
  languageStrings: {
    company: 'Empresa',
    companies: 'Empresas',
    briefing: {
      visibility: 'Si el briefing se pone en "oculto" únicamente lo visualizarán los usuarios admin, y en "visible" todos los usuarios de cualquier departamento.'
    }
  },
  analyticsLooker: '170ea96b-54c9-43fa-a55d-e472be585a65',
  adify: {
    actions: ['Aumentar', 'Reducir', 'Pausar', 'Mantener', 'Revisar'],
    platforms: ['Google Ads', 'Facebook Ads'],
    vertical: false,
    country: false
  },
  savvywords: {
    customers: []
  },
  insights: {
    filterByCompany: false,
    filterByPlatform: false
  }
})